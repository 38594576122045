<template>
  <v-app>
    <dashboard-core-header />
    <v-main class="py-14">
      <v-row class="ma-0">
        <v-col
          cols="12"
          md="10"
          offset-md="1"
          class="pt-0"
        >
          <v-system-bar
            v-if="online"
            color="yellow"
            class="py-5"
          >
            <v-container>
              <v-icon left>
                fas fa-unlink
              </v-icon>
              <strong>Você esta offline</strong>
            </v-container>
          </v-system-bar>
          <router-view />
        </v-col>
      </v-row>
    </v-main>

    <dashboard-core-footer />
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
      DashboardCoreHeader: () => import('./Header'),
    },

    data: () => ({
      online: false,
    }),

    mounted () {
      setInterval(() => {
        if (navigator.onLine) {
          this.online = false
        } else {
          this.online = true
        }
      }, 5000)
    },
  }
</script>
